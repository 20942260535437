import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

const Binding = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="blue">Back Home</p></NavLink>
						<h4 className="text-gray"><strong>API Binding</strong></h4>
						<div className="data-box">
							<div className="row">
								<div className="col-sm-5">
									<p className="text-white mb-0"><strong>ByBit</strong></p>
									<p className="text-white small-font mb-0">Status: <span className="pending">Inactive</span></p>
								</div>
								<div className="col-sm-7 mt-2 mt-sm-0">
									<p className="text-white mb-0"><strong>ID: ---</strong></p>
									<p className="text-white small-font mb-0">Key: ---</p>
								</div>
								<div className="col-12 mt-2">
									<button className="btn btn-primary mt-2" onClick={(e) => {
									}}>
										<p className="mb-0" style={{fontSize:"14px"}}>Bind</p>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Binding;