import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

const Trade = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">

					<div className="col-12 p-1">
						<NavLink to="/"><p className="blue">Back Home</p></NavLink>
						<h4 className="text-gray"><strong>Trading Summary</strong></h4>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0">Total Accumulative Profit (USDT)</h6>
							<p className="text-white balance-text mb-0">$4568.93</p>
							<p className="profit-green pnl-text mb-0">+50.32% (24h)</p>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0">Unrealized PnL (USDT)</h6>
							<p className="profit-green balance-text mb-0">+573.21</p>
							<p className="profit-green pnl-text mb-0">+6.83%</p>
						</div>
					</div>

					<div className="col-12 p-1">
						<h4 className="text-gray mt-5"><strong>Order Records</strong></h4>

						<div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Order #00001</strong></h6>
							<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Status: <span className="pending">Active</span></p>
							<hr className="divider"></hr>
							<div className="row">
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Order Amount:</p>
									<p className="profit-green order-text mb-0">{parseFloat(50).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Profit/Loss (%):</p>
									<p className="profit-green order-text mb-0">+ 36%</p>
								</div>
							</div>
						</div>

						<div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Order #00008 (Master)</strong></h6>
							<hr className="divider"></hr>
							<div className="row">
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Order Amount:</p>
									<p className="profit-green order-text mb-0">{parseFloat(5000).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Profit/Loss (%):</p>
									<p className="profit-green order-text mb-0">+ 36%</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Buy In:</p>
									<p className="text-gray order-text mb-0">55,768.89</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0" style={{fontSize:"12px"}}>Stop Loss:</p>
									<p className="pending order-text mb-0">53,800.00</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	);

};

export default Trade;