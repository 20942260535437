import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

const Bonus = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="blue">Back Home</p></NavLink>
						<h4 className="text-gray"><strong>Bonus</strong></h4>
						<div className="data-box">
							<h6 className="text-gray mb-0">Total Bonus (USDT)</h6>
							<p className="profit-green balance-text mb-0">${parseFloat(837).toFixed(2)}</p>
							<button className="btn btn-primary mt-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}>Withdraw</p>
							</button>
						</div>
					</div>

					<div className="col-12 p-1">
						<h4 className="text-gray mt-5"><strong>Summary</strong></h4>
						<div className="data-box">
							<h6 className="text-gray mb-0"><strong>Referral Bonus</strong></h6>
							<hr className="divider"></hr>
							<div className="row">
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0">Today's Bonus:</p>
									<p className="profit-green bonus-text mb-0">+ {parseFloat(50).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0">Accumulated Bonus:</p>
									<p className="yellow bonus-text mb-0">{parseFloat(350).toFixed(2)} USDT</p>
								</div>
							</div>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0"><strong>Team Bonus</strong></h6>
							<hr className="divider"></hr>
							<div className="row">
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0">Today's Bonus:</p>
									<p className="profit-green bonus-text mb-0">+ {parseFloat(57).toFixed(2)} USDT</p>
								</div>
								<div className="col-sm-6 mt-3">
									<p className="text-gray small-font mb-0">Accumulated Bonus:</p>
									<p className="yellow bonus-text mb-0">{parseFloat(487).toFixed(2)} USDT</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Bonus;