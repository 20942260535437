// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";


import MainLogo from './assets/img/logo.png';
import DummyIcon from './assets/img/icon.png';

import { isAddress } from 'ethers/lib/utils';

// Pages
import BindingPage from './pages/binding';
import TradePage from './pages/trade';
import BillingPage from './pages/billing';
import TeamPage from './pages/team';
import BonusPage from './pages/bonus';
import ProfilePage from './pages/profile';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {

  const queryParameters = new URLSearchParams(window.location.search);
  var referrerWallet = queryParameters.get("referral");

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功！");
	  setTimeout(() => setCopied(false), 3000);
	}

  const { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

	const userRegister = async () => {
    if (referrerWallet == null || referrerWallet == "") {
      referrerWallet = "0x687159e4f2786b10c977569bbb3a0c9c72873eaf";
    }
    console.log("Final Wallet:", referrerWallet);
    if (referrerWallet.toLowerCase() == blockchain.account.toLowerCase()) {
      setRegisterFeedback("不能推荐自己的钱包地址！");
      return;
    }
    if (!isAddress(referrerWallet) || referrerWallet.length < 42) {
      setRegisterFeedback("钱包地址不符合标准，请检查链接！");
      return;
    }

    await walletSignup(blockchain.account, referrerWallet);

    setTimeout(() => fetchUserData(), 2000);

	  // blockchain.rideChain.methods.register(referrerWallet).send({
    //   gasLimit: 80000,
    //   maxPriorityFeePerGas: 3000000000,
    //   maxFeePerGas: 3000000000,
    //   from: blockchain.account,
    //   value: 0,
	  // })
	  // // Transaction fail
	  // .once("error", (err) => {

	  // })
	  // // Transaction success
	  // .then((receipt) => {

	  // });
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};


  const [investData, setInvestData] = useState([]);

  const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

  const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
	  .then((results) => {
		console.log("Token Allowance:", results);
		setAllowance(results);
	  });
	};



  const getData = () => {
    if (blockchain.account !== "" && blockchain.rideChain !== null) {
      fetchUserData();
      getUserUSDTBalance();
      checkTokenAllowance();
    }
  };

  useEffect(() => {
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">

        <section className="header">
          <div className="container">
            <div className="row">
              <div className="col-7 p-1 d-flex justify-content-left">
                <div className="header-profile"></div>
                <h5 className="header-text text-gray mt-2 ml-2">Michael Lang</h5>
              </div>
              <div className="col-5 p-1 text-right">
                <button className="btn btn-primary" onClick={(e) => {
                }}>
                  <p className="mb-0" style={{fontSize:"14px"}}>Login</p>
                </button>
              </div>
            </div>
          </div>
        </section>

        <Router>
          <Routes>
            <Route path="/" element={
              <section className="section-preset">
                <div className="container">
                  <div className="row">

                    <div className="col-12 p-1">

                      <div className="data-box">
                        <h6 className="text-gray mb-0">BTC Price (USDT)</h6>
                        <p className="text-white balance-text mb-0">$60,388.96</p>
                        <p className="profit-green pnl-text mb-0">+5.72% (24h)</p>
                        {/* <MiddleEllipsis><span className="text-white" style={{fontSize:"12px"}}>https://ridechain.io?referral=</span></MiddleEllipsis> */}
                      </div>

                      <div className="data-box mt-3">
                        <h6 className="text-gray mb-0">My Wallet (USDT)</h6>
                        <p className="text-white balance-text mb-0">${parseFloat(1000000).toFixed(2)}</p>
                        <button className="btn btn-primary mt-2" onClick={(e) => {
                        }}>
                          <p className="mb-0" style={{fontSize:"14px"}}>Top-up</p>
                        </button>
                      </div>

                    </div>

                    <div className="col-12 mt-2">
                      <div className="row">
                        <div className="col-4 p-1">
                          <NavLink to="/binding">
                          <div className="data-box text-center">
                            <img className="menu-icon" src={DummyIcon}></img>
                            <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Binding</p>
                          </div>
                          </NavLink>
                        </div>
                        <div className="col-4 p-1">
                          <NavLink to="/trade">
                          <div className="data-box text-center">
                            <img className="menu-icon" src={DummyIcon}></img>
                            <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Trade</p>
                          </div>
                          </NavLink>
                        </div>
                        <div className="col-4 p-1">
                          <NavLink to="/billing">
                          <div className="data-box text-center">
                            <img className="menu-icon" src={DummyIcon}></img>
                            <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Billing</p>
                          </div>
                          </NavLink>
                        </div>
                        <div className="col-4 p-1">
                          <NavLink to="/team">
                          <div className="data-box text-center">
                            <img className="menu-icon" src={DummyIcon}></img>
                            <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Team</p>
                          </div>
                          </NavLink>
                        </div>
                        <div className="col-4 p-1">
                          <NavLink to="/bonus">
                          <div className="data-box text-center">
                            <img className="menu-icon" src={DummyIcon}></img>
                            <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Bonus</p>
                          </div>
                          </NavLink>
                        </div>
                        <div className="col-4 p-1">
                          <NavLink to="/profile">
                            <div className="data-box text-center">
                              <img className="menu-icon" src={DummyIcon}></img>
                              <p className="text-gray mt-2 mb-0" style={{fontSize:"12px"}}>Profile</p>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-5 p-1">
                      <div className="data-box mt-3">
                        <h6 className="text-gray mb-0">Total Accumulative Profit (USDT)</h6>
                        <p className="text-white balance-text mb-0">$4568.93</p>
                        <p className="profit-green pnl-text mb-0">+50.32% (24h)</p>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            }/>
            <Route path="/binding" element={<BindingPage/>}/>
            <Route path="/trade" element={<TradePage/>}/>
            <Route path="/billing" element={<BillingPage/>}/>
            <Route path="/team" element={<TeamPage/>}/>
            <Route path="/bonus" element={<BonusPage/>}/>
            <Route path="/profile" element={<ProfilePage/>}/>
          </Routes>
        </Router>

      </div>
    </div>
  );
}

export default App;