import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

const Team = () => {

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="blue">Back Home</p></NavLink>
						<h4 className="text-white"><strong>Referral Program</strong></h4>
						<p className="text-gray mb-0">Invite your friends to trade with us and earn up to 5% bonus from your team's trading profits.</p>
						<div className="data-box mt-3">
							<h6 className="text-white mb-0"><strong>My Referral Code</strong></h6>
							<p className="text-gray mb-0">https://smgtradegod.com?referral=XB0K92Y8</p>
							<CopyToClipboard 
								text={"https://smgtradegod.com?referral=XB0K92Y8"}
								onCopy={() => copyReferral()}>
								<button className="btn btn-primary mt-2" onClick={(e) => {
								}}>
									{!copied ? (
										<p className="mb-0">Copy</p>
									):(
										<p className="mb-0">{copyText}</p>
									)}
								</button>
							</CopyToClipboard>
						</div>
						<div className="data-box mt-3">
							<h6 className="text-gray mb-0">Total Team Bonus (USDT)</h6>
							<p className="profit-green balance-text mb-0">${parseFloat(487).toFixed(2)}</p>
						</div>
					</div>
					<div className="col-12 mt-1">
						<div className="row">
							<div className="col-5 p-1">
								<div className="data-box">
									<p className="text-gray small-font mb-0">Referrals:</p>
									<p className="yellow bonus-text mb-0">0</p>
								</div>
							</div>
							<div className="col-7 p-1">
								<div className="data-box">
									<p className="text-gray small-font mb-0">Trade Volume (USDT):</p>
									<p className="profit-green bonus-text mb-0">{parseFloat(38000).toFixed(2)}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Team;