import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

const Profile = () => {

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<NavLink to="/"><p className="blue">Back Home</p></NavLink>
						<h4 className="text-gray"><strong>My Profile</strong></h4>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Profile;